const Features = () => {
  const fetureList = [
    "Video Production Management Dashboard",
    "Create storyboard",
    "Create your Project and onboard your team",
    "Hire freelancers for your open requirements from 1000s of onboard creators",
    "Upload and share your video and audio recordings with team",
    "Select colors and Theme from suggestions",
    "Use 1000s of free to use videos and images for your video creation project",
    "Edit using our video editing tool",
    "Communicate and discuss with project team regarding project",
    "Validate, review and comment your remarks on production progress or production videos to request changes",
    "Manage work and payment of your freelances",
    "Buy or sell videos to and from other creative producers",
  ];

  const FeatureComponent = fetureList.map((el) => (
    <div className="flex flex-row justify-center items-center w-40 h-40 p-3 m-3 text-sm bg-[#fefce1] rounded">
      {el}
    </div>
  ));
  return (
    <div className="m-2 md:m-10">
      <div className="text-md font-semibold py-10">Salient Features</div>
      <div className="flex flex-wrap">{FeatureComponent}</div>
    </div>
  );
};

export default Features;
