const Footer = () => {
  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between">
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="p-10">
          <img src="https://website-creator-images.s3.amazonaws.com/sitehex/logo/sitehex-logo-134x134.png" />
        </div>
        <div className="p-10 w-80">
          A colors, images, videos, content production and management tool
        </div>
      </div>
      <div className="p-10">
        <div>Privacy Policies</div>
        <div>All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Footer;
