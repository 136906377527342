import HexInputToColor from "./HexInputToColor";

const ColorPicker = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center bg-yellow-100">
      <div className="pl-10 pt-10">
        <h2 className="text-2xl font-bold">SiteHex Color Picker</h2>
        <div className="py-5 pr-5 text-sm">
          Enter your hex color code or choose a color using color picker
        </div>
      </div>
      <HexInputToColor />
    </div>
  );
};

export default ColorPicker;
