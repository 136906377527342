import ColorPicker from "./ColorPicker";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header";
import HeroContent from "./HeroContent";
import StockMedia from "./StockMedia";
import Talents from "./Talents";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <HeroContent />
      <ColorPicker />
      <Talents />
      <StockMedia />
      <Features />
      <Footer />
    </div>
  );
};

export default LandingPage;
