import { useEffect, useState } from "react";

const HexInputToColor = () => {
  const [hexCode, setHexCode] = useState("#ADD8E6");

  return (
    <div className="p-10">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-row mt-3">
          <div className="flex flex-row mx-5">
            <input
              type="text"
              className="p-4 w-48 h-16 -mr-10"
              onChange={(e) => setHexCode(e.target.value)}
              value={hexCode}
              placeholder="#ADD8E6"
            />
            <button
              className="text-xs text-gray-400 active:text-black"
              onClick={() => {
                navigator.clipboard.writeText(hexCode);
              }}
            >
              copy
            </button>
          </div>
          <div className="flex h-10">
            <input
              className="h-full"
              type="color"
              id="favcolor"
              name="favcolor"
              value={hexCode}
              onChange={(e) => setHexCode(e.target.value)}
            />
          </div>
        </div>

        <div
          className="w-60 h-16 mx-5 border border-1 mt-3"
          style={{ backgroundColor: hexCode }}
        ></div>
      </div>
    </div>
  );
};

export default HexInputToColor;
