const StockMedia = () => {
  const imagesUrls = ["a", "b", "c", "d", "e"];
  const imagesReel = imagesUrls.map((e, i) => {
    return (
      <div className="m-3 md:m-5 w-32 flex justify-center items-center h-24 bg-gray-300 text-sm p-3 text-gray-400">
        Image Placehoder
      </div>
    );
  });

  return (
    <div className="flex flex-col justify-center pb-10">
      <div className="pl-10 pt-12 pb-6">
        <h2 className="text-2xl font-bold"></h2>
      </div>
      <div className="p-3">
        <input
          type="text"
          placeholder="Search From 1000s of Stock Images and Videos"
          className="w-full h-12 bg-white border border-1 p-3 font-semibold"
        />
      </div>
      <div className="flex justify-evenly md:flex-row p-2 md:pl-10">
        <div className="flex flex-wrap justify-evenly items-center justify-between">
          {imagesReel}
        </div>
        <button className="p-2 bg-gray-200 active:bg-gray-300">{`>`}</button>
      </div>
    </div>
  );
};

export default StockMedia;
