const BannerText = () => {
  return (
    <div className="flex justify-center flex-1  pl-20 p-10">
      <div className="max-w-96 content-end">
        <div className="text-4xl">
          Create and manage your next video production project
        </div>
        <div className="text-xs pt-10">
          Manage Progress | Create Storyboard | Discuss Ideas | Share Recordings
        </div>
      </div>
    </div>
  );
};

export default BannerText;
