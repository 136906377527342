import Navbar from "./Navbar";

const Header = () => {
  return (
    <div className="flex flex-row justify-between p-5">
      <div className="pl-16">
        <img
          src="https://website-creator-images.s3.amazonaws.com/sitehex/logo/sitehex-logo-150x45.png"
          alt="sitehex logo"
        />
      </div>
      <Navbar />
    </div>
  );
};

export default Header;
