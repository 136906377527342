import { useState } from "react";

const Navbar = () => {
  const [active, setActive] = useState(false);

  const showMobileMenu = () => {
    setActive((prev) => !prev);
  };
  return (
    <>
      <button className="md:hidden text-4xl" onClick={showMobileMenu}>
        &#8801;
      </button>
      <div
        className={`${
          active ? "flex" : "hidden"
        } md:flex flex-col md:flex-row md:justify-between absolute md:relative bg-gray-200 md:bg-transparent text-lg md:text-sm ml-52 mt-16 md:m-0 `}
      >
        <div className="p-5">Colors</div>
        <div className="p-5">Images</div>
        <div className="p-5">Videos</div>
        <div className="p-5">Project Manager</div>
      </div>
    </>
  );
};

export default Navbar;
