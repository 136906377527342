const Talents = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col md:flex-row ">
        <div className="pl-10 pt-10 pr-5">
          <h2 className="text-xl">Hire Creative Video Editors</h2>
          <div className="py-5 pr-5 text-sm">
            Hire creative and experience video editors form 1000s of available
            freelancers
          </div>
        </div>
        <div className="flex items-center pl-5 ">
          <button className="p-2 bg-blue-200 m-5 active:bg-blue-300 rounded">
            Join as freelancer
          </button>
          <button className="p-2 bg-blue-200 m-5 active:bg-blue-300 rounded">
            Hire freelancer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Talents;
