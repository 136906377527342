const LoginSignup = () => {
  return (
    <div className="flex-1 m-10 ">
      <div className="p-10 h-72 max-w-80 bg-yellow-100">
        <div className="text-sm font-semibold">Login/Signup</div>
        <div className="pt-5">
          <input
            className="p-2 border border-1 rounded w-full"
            placeholder={"email"}
          />
        </div>
        <div className="pt-5">
          <input
            className="p-2 border border-1 rounded w-full"
            placeholder={"password"}
          />
        </div>
        <div className="pt-5">
          <button className="p-2 bg-blue-200 w-full">Login/Sign-up</button>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
