import BannerText from "./BannerText";
import LoginSignup from "./LoginSignup";

const HeroContent = () => {
  return (
    <div className="w-full flex flex-col md:flex-row md:h-96 items-center">
      <BannerText />
      <LoginSignup />
    </div>
  );
};

export default HeroContent;
